<template>
  <div>
    <div style="height: 100px"></div>
    <div class="d-flex">
      <div style="width:20%"></div>
      <div>
        <h1>404</h1>
        <v-alert class="ma-0 pa-2" border="left" colored-border color="grey">
          <p class="grey--text mb-0 ml-2">
            Halaman yang Anda tuju tidak ada.
          </p>
        </v-alert>
        <v-btn text color="indigo" @click="takeMeHome">
          Bawa aku pulang.
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pageNotFound",
  data() {
    return {};
  },
  methods: {
    takeMeHome() {
      this.$router.push("/");
    }
  }
};
</script>

<style></style>
